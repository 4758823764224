<template>
    <div class="blog">
        <div style="display: flex;align-items: center;justify-content: center;margin-top: 10px;">
            <el-image :src="blog.img[0]" style="width: 350px;height: 300px;">
            </el-image>
        </div>
        <div style="display: flex;align-items: center;justify-content: center;margin-top: 20px;">
            <el-text style="font-size: larger;color: black;">{{ blog.title }}</el-text>
        </div>
        <div style="display: flex;align-items: center;justify-content: center;margin-top: 20px;">
            <div v-html="blog.content"></div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.blog {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;

    padding-left: 10%;
    padding-right: 10%;


    min-height: 450px;
}

@media (max-width: 992px) {
    .blog {
        padding-left: 0;
        padding-right: 0;

        min-height: 600px;

    }
}
</style>

<script setup>
import router from '@/router';
import $ from 'jquery'
import { reactive } from 'vue'
const blog_id = router.currentRoute.value.params.id;
const blog = reactive({
    title: "",
    content: "",
    img: []
})

if (blog_id) {
    $.ajax({
        // 主键id
        url: "https://hualianrui.com:3001/blog/list/" + blog_id,
        type: "get",
        header: {
            "Content-Type": "application/json"
        },
        success(resp) {
            blog.title = resp.data.title;
            blog.content = resp.data.content;

            blog.img.push(resp.data.img);

        },
        error() {
            open("请求失败", "error");
        }
    })
}

</script>