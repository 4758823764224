<template>
    <div class="blog">
        <div class="blog-img">
            <el-image :src="back" style="width: 100%;height: 200px;"></el-image>
        </div>
        <div class="blog-list">
            <div v-for="item in blogs" :key="item" style="margin: 5px;">
                <blog :item="item" @click="jump(item.id)"></blog>
            </div>
        </div>
        <div class="blog-page">
            <el-pagination small background layout="prev, pager, next" :total="page.total * page.pageSize"
                v-model:page-size="page.pageSize" v-model:current-page="page.pageNum" />
        </div>
    </div>
</template>

<style lang="less" scoped>
.blog {
    display: flex;
    flex-direction: column;

    .blog-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        justify-content: center;
        min-height: 400px;
    }

    .blog-page {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
    }

}
</style>

<script setup>
import blog from '@/components/BlogView.vue'
import router from '@/router';
import $ from 'jquery';
import back from '@/assets/background.png'
import { reactive, ref, watch } from 'vue';
const page = reactive({
    pageNum: 1,
    pageSize: 15,
    total: 1
})

const blogs = ref([]);
const blogList = () => {
    $.ajax({
        url: `https://hualianrui.com:3001/blog/list?pageNum=${page.pageNum}&pageSize=${page.pageSize}`,
        type: "get",
        header: {
            "Content-Type": "application/json"
        },
        success(resp) {
            if (resp.msg == "操作成功") {
                blogs.value = resp.data.rows;
                page.total = resp.data.total;
            }
        },
        error() {

        }
    })
}

blogList();

const jump = (id) => {
    router.push({
        name: "blog",
        params: {
            id: id
        }
    })
}

watch(page, () => {
    blogList();
})
</script>