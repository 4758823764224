import $ from 'jquery'

export default ({
    state: {
        imgs: [],
        tel: "",
        email: "",
        address: ""
    },
    getters: {},
    mutations: {
        update(state, web) {
            state.email = web.email;
            state.address = web.address;
            state.tel = web.telephone;
            state.imgs = web.photos;
        }
    },
    actions: {
        webinfo(context) {
            $.ajax({
                url: "https://hualianrui.com:3001/web/info",
                type: "get",
                success(resp) {
                    context.commit("update", resp);
                }
            })
        }
    },
    modules: {

    }
})