<template>
    <div class="product">
        <div class="product-left">
            <div style="display: flex;justify-content: center;">
                <el-carousel style="width: 300px;height: 350px;">
                    <el-carousel-item v-for="item in product.img" :key="item">
                        <el-image :src="item.url" style="height: 100%;width: 100%;"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;margin-top: 20px;">
                <el-text style="font-size: larger;color: black;">{{ product.name }}</el-text>
            </div>
        </div>
        <div class="product-right">
            <div v-html="product.content"></div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.product {
    display: flex;
    padding-left: 10%;
    padding-right: 10%;

    margin-top: 20px;

    min-height: 450px;

    .product-left {
        margin-top: 40px;
    }

    .product-right {
        margin-left: 40px;
    }
}

@media (max-width:992px) {
    .product {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        min-height: 600px;

        .product-left {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .product-right {
            margin-left: 0;
            margin-top: 10px;
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
</style>

<script setup>
import router from '@/router';
import { reactive } from 'vue'
import $ from 'jquery'
const product = reactive({
    name: "",
    content: "",
    img: []
})
const product_id = router.currentRoute.value.params.id;

if (product_id) {
    $.ajax({
        // 主键id
        url: "https://hualianrui.com:3001/product/list/" + product_id,
        type: "get",
        header: {
            "Content-Type": "application/json"
        },
        success(resp) {
            product.name = resp.data.name;
            product.content = resp.data.content;

            for (let img of resp.data.img) {
                let newFile = {
                    url: img,
                    name: img
                }
                product.img.push(newFile);
            }
        },
        error() {
            open("请求失败", "error");
        }
    })
}

</script>