<template>
    <div class="products">
        <div class="products-img">
            <el-image :src="back" style="width: 100%;height: 200px;"></el-image>
        </div>
        <div class="products-list">
            <div v-for="item in products" :key="item" style="margin: 5px;">
                <product :item="item" @click="jump(item.id)"></product>
            </div>
        </div>

        <div class="products-page">
            <el-pagination small background layout="prev, pager, next" :total="page.total * page.pageSize"
                v-model:page-size="page.pageSize" v-model:current-page="page.pageNum" />
        </div>
    </div>
</template>

<style lang="less" scoped>
.products {
    display: flex;
    flex-direction: column;

    .products-list {

        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        justify-content: center;

        min-height: 400px;
    }

    .products-page {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
    }
}
</style>

<script setup>
import product from '@/components/ProductView.vue';
import router from '@/router';
import back from '@/assets/background.png'
import $ from 'jquery';
import { reactive, ref, watch } from 'vue';

const page = reactive({
    pageNum: 1,
    pageSize: 8,
    total: 1
})

const products = ref([]);

const productList = () => {
    $.ajax({
        url: `https://hualianrui.com:3001/product/list?pageNum=${page.pageNum}&pageSize=${page.pageSize}`,
        type: "get",
        header: {
            "Content-Type": "application/json"
        },
        success(resp) {
            if (resp.msg == "操作成功") {
                products.value = resp.data.rows;
                page.total = resp.data.total;
            }
        },
        error() {

        }
    })
}

productList();

const jump = (id) => {
    router.push({
        name: "product",
        params: {
            id: id
        }
    })
}

watch(page, () => {
    productList();
})
</script>