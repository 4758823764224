import {
  createRouter,
  createWebHistory
} from 'vue-router'
import HomeView from '@/views/home/HomeView.vue'
import Products from '@/views/product/ProductsView.vue'
import Product from '@/views/product/ProductView.vue'
import Blogs from '@/views/blog/BlogsView.vue'
import Blog from '@/views/blog/BlogView.vue'
const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/products',
    name: 'products',
    component: Products
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: Blogs
  },
  {
    path: '/products/:id',
    name: "product",
    component: Product
  },
  {
    path: "/blogs/:id",
    name: "blog",
    component: Blog
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router