<template>
    <div class="navbar">
        <div class="navbar-left">
            <div class="navbar-logo">
                <img src="@/assets/logo.jpg" style="height: 60px;width: 400px;">
            </div>
            <div class="navbar-link">
                <RouterLink :to="{ name: 'home' }">
                    <div class="link">
                        Home
                    </div>
                </RouterLink>

                <RouterLink :to="{ name: 'products' }">
                    <div class="link">
                        Products
                    </div>
                </RouterLink>

                <RouterLink :to="{ name: 'blogs' }">
                    <div class="link">
                        Blog
                    </div>
                </RouterLink>

            </div>


            <div class="navbar-link-min" @click="link = true">
                <svg t="1706534982201" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="4239" width="32" height="32">
                    <path
                        d="M417.185031 568.889057a37.925912 37.925912 0 0 1 37.925912 37.925912v341.333207a37.925912 37.925912 0 0 1-37.925912 37.925912H75.851824a37.925912 37.925912 0 0 1-37.925912-37.925912V606.814969a37.925912 37.925912 0 0 1 37.925912-37.925912h341.333207m0-37.925911H75.851824a75.851824 75.851824 0 0 0-75.851824 75.851823v341.333207a75.851824 75.851824 0 0 0 75.851824 75.851824h341.333207a75.851824 75.851824 0 0 0 75.851823-75.851824V606.814969a75.851824 75.851824 0 0 0-75.851823-75.851823zM948.147797 568.889057a37.925912 37.925912 0 0 1 37.925912 37.925912v341.333207a37.925912 37.925912 0 0 1-37.925912 37.925912H606.81459a37.925912 37.925912 0 0 1-37.925912-37.925912V606.814969a37.925912 37.925912 0 0 1 37.925912-37.925912h341.333207m0-37.925911H606.81459a75.851824 75.851824 0 0 0-75.851824 75.851823v341.333207a75.851824 75.851824 0 0 0 75.851824 75.851824h341.333207a75.851824 75.851824 0 0 0 75.851824-75.851824V606.814969a75.851824 75.851824 0 0 0-75.851824-75.851823zM417.185031 37.926291a37.925912 37.925912 0 0 1 37.925912 37.925912v341.333207a37.925912 37.925912 0 0 1-37.925912 37.925912H75.851824a37.925912 37.925912 0 0 1-37.925912-37.925912V75.852203a37.925912 37.925912 0 0 1 37.925912-37.925912h341.333207m0-37.925912H75.851824a75.851824 75.851824 0 0 0-75.851824 75.851824v341.333207a75.851824 75.851824 0 0 0 75.851824 75.851824h341.333207a75.851824 75.851824 0 0 0 75.851823-75.851824V75.852203a75.851824 75.851824 0 0 0-75.851823-75.851824z"
                        fill="#333333" p-id="4240"></path>
                    <path
                        d="M777.481194 37.926291a20.859252 20.859252 0 0 1 14.791105 6.068146l187.354005 187.543634a21.048881 21.048881 0 0 1 0 29.771841l-187.354005 187.543634a20.859252 20.859252 0 0 1-29.582211 0l-187.354005-187.543634a21.048881 21.048881 0 0 1 0-29.771841l186.974746-187.354004A20.859252 20.859252 0 0 1 777.481194 37.926291m0-37.925912a58.595534 58.595534 0 0 0-41.718504 17.25629l-187.733263 187.733264a58.974793 58.974793 0 0 0 0 83.247377l187.354004 187.543634a58.785163 58.785163 0 0 0 83.247377 0l187.354005-187.543634a58.974793 58.974793 0 0 0 0-83.247377L818.820437 17.446299A58.595534 58.595534 0 0 0 777.481194 0.000379z"
                        fill="#19A764" p-id="4241"></path>
                </svg>
            </div>
            <el-drawer v-model="link" direction="rtl" size="200">
                <div style="display: flex;flex-direction: column;">
                    <RouterLink :to="{ name: 'home' }" style="display:flex;justify-content:center;">
                        <el-button class="link" text>
                            <el-text style="font-size: large;">Home</el-text>
                        </el-button>
                    </RouterLink>
                    <RouterLink :to="{ name: 'products' }" style="display:flex;justify-content:center;margin-top: 10px;">
                        <el-button class="link" text>
                            <el-text style="font-size: large;">Products</el-text>
                        </el-button>
                    </RouterLink>
                    <RouterLink :to="{ name: 'blogs' }" style="display:flex;justify-content:center;margin-top: 10px;">
                        <el-button class="link" text>
                            <el-text style="font-size: large;">Blog</el-text>
                        </el-button>
                    </RouterLink>
                </div>
            </el-drawer>
        </div>
        <div class="navbar-right">
            <el-text style="color: rgb(29,32,136);font-size: large;">Tel: {{ store.state.web.tel }}</el-text>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import store from '@/store';
import { ElMessage } from 'element-plus';
const link = ref(false);

store.dispatch("webinfo", {
    success() {

    },
    error() {
        open("Web Error", "error");
    }
})

const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}
</script>

<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}

:deep(.el-tooltip__trigger:focus-visible) {
    outline: unset;
}
</style>

<style lang="less" scoped>
.navbar {

    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;

    .navbar-left {

        height: 100%;
        display: flex;

        .navbar-logo {
            height: 100%;
            width: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .navbar-link {
            display: flex;

            position: relative;

            .link {
                height: 100%;
                width: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: gray;
                font-size: larger;

                &:hover {
                    color: black;
                }

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    height: 5px;
                    background-color: rgb(29, 32, 136);
                    width: 0;
                    transition: width .25s ease-in-out;
                }

                &:hover::after {
                    width: 120px;
                }

            }
        }

        .navbar-link-min {
            display: none;
        }
    }

    .navbar-right {
        height: 100%;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 992px) {
    .navbar {
        justify-content: center;
        height: 60px;

        .navbar-left {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .navbar-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                width: 210px;
            }

            .navbar-link {
                display: none;
            }

            .navbar-link-min {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100%;
            }
        }

        .navbar-right {
            display: none;
        }
    }
}
</style>