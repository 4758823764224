<template>
    <div class="product">
        <div class="product-img">
            <el-image :src="item.img[0]" fit="fill" class="img"></el-image>
        </div>
        <div class="product-name">
            <el-text style="font-size: medium;">{{ item.name }}</el-text>
        </div>
    </div>
</template>

<style lang="less" scoped>
.product {

    width: 260px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .product-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        height: 250px;

        .img {
            width: 200px;
            height: 200px;
        }
    }

    .product-name {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
}
</style>

<script>
export default {
    props: {
        item: {
            default: ""
        }
    },
}
</script>