<template>
  <div class="home">
    <div class="home-carousel">
      <el-carousel class="home-img" :height="imgHeight + 'px'">
        <el-carousel-item v-for="item in store.state.web.imgs" :key="item" class="home-img-item">
          <el-image :src="item" style="width: 100%;height: 100%;"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home-product">
      <div class="product-title">
        <el-text style="font-size: larger;">Products</el-text>
      </div>
      <div class="products-list">
        <div @click="downpage">
          <svg t="1706685837640" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="5053" width="32" height="32">
            <path
              d="M477.866667 533.333333L768 243.2l-29.866667-29.866667-320 320 320 320 29.866667-29.866666-290.133333-290.133334zM298.666667 213.333333H256v640h42.666667V213.333333z"
              fill="#444444" p-id="5054"></path>
          </svg>
        </div>
        <div v-for="item in 1" :key="item" class="products-list-content">
          <div v-for="item in products" :key="item" style="margin: 5px;">
            <product :item="item" @click="jump(item.id)"></product>
          </div>
        </div>
        <div @click="uppage">
          <svg t="1706685869086" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="5207" width="32" height="32">
            <path
              d="M546.133333 533.333333L256 243.2l29.866667-29.866667 320 320L285.866667 853.333333l-29.866667-29.866666 290.133333-290.133334zM725.333333 213.333333h42.666667v640h-42.666667V213.333333z"
              fill="#444444" p-id="5208"></path>
          </svg>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import $ from 'jquery'
import { ref, reactive, watch } from 'vue';
import product from '@/components/ProductView.vue';
import router from '@/router';
import store from '@/store'

const imgHeight = ref(500)

const page = reactive({
  pageNum: 1,
  pageSize: 15,
  total: 1
})
const handleResize = () => {
  if ($(window).width() <= 992) {
    imgHeight.value = 300;
    page.pageSize = 1;
  } else {
    imgHeight.value = 500;
    page.pageSize = 4;
  }
}

handleResize()

const uppage = () => {
  page.pageNum = Math.min(page.total, page.pageNum + 1);
}

const downpage = () => {
  page.pageNum = Math.max(page.pageNum - 1, 1);
}

$(window).on("resize", handleResize);

const products = ref([]);

const productList = () => {
  $.ajax({
    url: `https://hualianrui.com:3001/product/list?pageNum=${page.pageNum}&pageSize=${page.pageSize}`,
    type: "get",
    header: {
      "Content-Type": "application/json"
    },
    success(resp) {
      if (resp.msg == "操作成功") {
        products.value = resp.data.rows;
        page.total = resp.data.total;
      }
    },
    error() {

    }
  })
}

const jump = (id) => {
  router.push({
    name: "product",
    params: {
      id: id
    }
  })
}


productList();

watch(page, () => {
  productList();
})
</script>

<style lang="less" scoped>
.home {

  .home-carousel {
    .home-img {
      width: 100%;
    }
  }

  .home-product {
    height: 500px;

    .product-title {
      display: flex;
      justify-content: center;
      height: 100px;
      align-items: center;
    }

    .products-list {
      display: flex;
      justify-content: center;
      align-items: center;

      .products-list-content {
        display: flex;
      }
    }
  }
}
</style>